import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  SESSION_USERID_UPDATE,
  NEW_FORM_ERRORS,
  NEW_FIELD_SPECIFIC_ERROR,
} from "patient_app/constants/actionTypes";

import {
  sendResetPasswordInstructions,
  initiatePasswordReset,
} from "patient_app/api/sessionActions";

import MainLayout from "patient_app/components/layouts/MainLayout";
import ContentMain from "patient_app/components/forms/ContentMain";
import ContentBox from "patient_app/components/forms/ContentBox";
import FormErrors from "patient_app/components/utils/FormErrors";
import OnboardingField from "patient_app/views/onboarding/partials/OnboardingField";
import SubmitButton from "patient_app/components/buttons/SubmitButton";
import SelectButton from "patient_app/components/buttons/SelectButton";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { loading, errors, userId, email, phone } = useSelector((state) => ({
    loading: state.common.loading,
    errors: state.common.errors,
    userId: state.session.userId,
  }));

  useEffect(() => {
    handleUpdateField(null);
    dispatch({ type: SESSION_USERID_UPDATE, email: null });
  }, []);

  const handleUpdateField = (value) => {
    dispatch({ type: SESSION_USERID_UPDATE, userId: value });
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();

    dispatch({ type: NEW_FORM_ERRORS, errors: [] });

    if (!userId || userId.length < 5) {
      dispatch({
        type: NEW_FORM_ERRORS,
        errors: [{ text: "Please correct the issues listed below" }],
      });
      dispatch({
        type: NEW_FIELD_SPECIFIC_ERROR,
        field: "userId",
        error: "Please enter a valid phone number or email.",
      });
      return;
    }

    setFormSubmitted(true);
    dispatch(initiatePasswordReset(userId, history));
  };

  const handleSelect = (value) => {
    dispatch(sendResetPasswordInstructions(userId, value, history));
  };

  return (
    <MainLayout className="Page-Password-Reset">
      <ContentMain>
        <ContentBox className="onboarding password_reset">
          <div className="inputs">
            {!formSubmitted ? (
              <>
                <h1>Enter Your Email or Phone Number</h1>
                <FormErrors />
                <OnboardingField
                  field="userId"
                  fieldType="email"
                  required
                  title="Email or Phone"
                  value={userId}
                  onKeyPress={(e) => e.keyCode === 13 && handleSubmit()}
                  onUpdateField={handleUpdateField}
                />
                <SubmitButton
                  text="Continue"
                  loading={loading}
                  onSubmit={handleSubmit}
                />
              </>
            ) : (
              <>
                <h1>How would you like to reset your password?</h1>
                <SelectButton
                  id="select-email"
                  value="email"
                  text={`Send link to email`}
                  onSelect={() => handleSelect("email")}
                  classNames="full-width allow-overflow green"
                />
                <SelectButton
                  id="select-phone"
                  value="phone"
                  text={`Send link to phone`}
                  onSelect={() => handleSelect("phone")}
                  classNames="full-width allow-overflow green"
                />
              </>
            )}
          </div>
        </ContentBox>
      </ContentMain>
    </MainLayout>
  );
};

export default ResetPassword;
