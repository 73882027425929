const baseSpacing = 8;
export const variables = {
  boxShadow: "0 7px 14px 0 rgba(50,50,93,.1), 0 3px 6px 0 rgba(0,0,0,.07)",
  objLight: `'obj3-light', 'arial'`,
  objReg: `'obj3-reg', 'arial'`,
  objMed: `'obj3-med', 'arial'`,
  objBold: `'obj3-bold', 'arial'`,
  hover: "rgba(230,231,234,0.3)",

  // Colors Figma: https://www.figma.com/file/n2ZJk22kXw0BafoH5zcFYl/Brand?node-id=0%3A1

  // reds
  redWhite: "hsl(13, 71%, 80%)", // redWhite: "#F0B7A8",
  red: "hsl(353, 57%, 53%)", //red: "#CB4353",
  redDark: "hsl(353, 49%, 36%)", //redDark: "#892F39",
  whiteRed: "#FFEEEA", //extra pale, used for banner backgrounds

  // yellows
  yellowWhite: "hsl(40, 100%, 92%)",
  yellowLight: "hsl(36, 100%, 84%)",
  yellow: "hsl(36, 87%, 74%)", //yellow: "#F6C882",
  orange: "hsl(19, 80%, 61%)",

  // greens
  greenWhite: "hsl(84, 47%, 89%)", //whiteGreen: "##E6F0D6",
  greenLight: "hsl(155, 46%, 55%)",
  green: "hsl(169, 80%, 28%)", //green: "#0E816C",
  greenDark: "hsl(177, 69%, 20%)",

  // blues
  blueWhite: "hsl(219, 79%, 94%)", //blueWhite: "#E5EDFC",
  blueLight: "hsl(216, 63%, 54%)",
  blue: "hsl(215, 65%, 29%)", // blue: "#1A4279",
  blueDark: "#06140F", // blueDark: "#06140F",

  // purples
  purpleWhite: "hsl(325, 63%, 91%)", //lightPink: "#F6DAEA",
  purple: "hsl(325, 27%, 51%)",
  purpleDark: "hsl(327, 40%, 35%)", //darkPurple: "#7B355C",

  //grays
  grayWhite: "hsl(0, 0%, 95%)", // #F3F3F3
  grayLight: "hsl(225, 9%, 91%)", //"#E6E7EA",
  gray: "hsl(0, 0%, 46%)", //"#757575"
  grayBlue: "#63708C",
  grayBorder: "#E6E7EA",
  dark: "#586875",

  // old colors
  whiteGreen: "#DDF4EF", // use greenWhite
  darkBlue: "#06140F", // use blueDark
  whiteBlue: "#E5EDFC", // use blueWhite
  headerTextColor: "#7A848C",
  boxBorder: "hsl(225, 9%, 91%)", // use grayLight
  grayTrim: "#DADADA", // use grayLight
  medGray: "#616680", // use gray
  lightGray: "#F8FAFC", // - use whiteGray
  offGray: "#9499AC", // use gray
  backgroundGray: "#EDEDEF",

  //layout and spacing
  tiny: `${baseSpacing}px`,
  small: `${baseSpacing * 2}px`,
  base: `${baseSpacing * 4}px`,
  large: `${baseSpacing * 6}px`,
  xLarge: `${baseSpacing * 8}px`,

  pFontSize: "0.8rem",
  h1FontSize: "1.2rem",
};
