import css from "styled-jsx/css";
import { variables } from "patient_app/stylesheets/variables";

export default css.global`
  .drag-and-drop {
    margin-bottom: ${variables.base};
  }
  .drag-and-drop {
    margin-bottom: ${variables.base};
  }

  .drag-and-drop .drop-box {
    height: calc(16px * 12);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${variables.lightGray};
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    margin-bottom: 4px;
    padding: ${variables.base};
    box-sizing: border-box;
  }

  .drag-and-drop .actions-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .drag-and-drop button.select-file {
    background-color: white;
    border: 1px solid ${variables.boxBorder};
    border-radius: 4px;
    color: ${variables.gray};
    height: calc(16px * 3);
    width: 50%;
    margin-bottom: ${variables.small};
    font-size: 0.8rem;
    font-family: ${variables.objReg};
  }

  .drag-and-drop a.remove-file {
    text-decoration: underline;
    color: ${variables.gray};
  }

  .drag-and-drop .picture-input {
    display: none;
  }

  .drag-and-drop p {
    color: ${variables.gray};
    font-size: 0.8rem;
    font-family: ${variables.objReg};
    margin: 0;
    text-align: center;
  }

  .drag-and-drop a {
    color: ${variables.green};
    text-decoration: underline;
  }

  .drag-and-drop .field-error {
    font-size: 0.7rem;
    color: ${variables.red};
  }

  .drag-and-drop .title {
    display: block;
    font-family: obj3-med, arial;
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: #06140f;
  }
`;
